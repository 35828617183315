// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-characters-character-id-tsx": () => import("./../../../src/pages/app/characters/[characterID].tsx" /* webpackChunkName: "component---src-pages-app-characters-character-id-tsx" */),
  "component---src-pages-app-creations-tsx": () => import("./../../../src/pages/app/creations.tsx" /* webpackChunkName: "component---src-pages-app-creations-tsx" */),
  "component---src-pages-app-home-tsx": () => import("./../../../src/pages/app/home.tsx" /* webpackChunkName: "component---src-pages-app-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

